import React from 'react';
import { Social } from './social/social';
import { container, header, text } from './welcome.module.css';
export const Welcome = () => {
  return (
    <div className={container}>
      <div className={header}>Welcome to my blog!</div>
      <div className={text}>
        I write about my journey as a software developer, problems and
        solutions I come across and technologies thats interests me
        such as .NET, TypeScript, Devops and Cloud
      </div>
      <p></p>
      <div className={text}>
        I'm a father, husband, tech enthusiast, independent software
        developer consultant, working with software and teams in
        Stockholm, Sweden.
      </div>
      <Social />
    </div>
  );
};
