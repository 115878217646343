import React from 'react';
import { Tag } from './tag/tag';
import { container, header } from './tags.module.css';

export const Tags = (tags: string[][]) => {
  const groupedTags: Record<string, number> = {};
  Object.values(tags).forEach((values) => {
    values.forEach((val) => {
      if (groupedTags[val] === undefined) {
        groupedTags[val] = 1;
      } else {
        groupedTags[val] = +groupedTags[val] + 1;
      }
    });
  });

  return (
    <div className={container}>
      <div className={header}>tags</div>
      {Object.keys(groupedTags).map((tag, i) => {
        return <Tag key={tag} name={tag} amount={groupedTags[tag]} />;
      })}
    </div>
  );
};
