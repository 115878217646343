import {
  faGithub,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { container, box, icon } from './social.module.css';

export const Social = () => {
  return (
    <div className={container}>
      <a
        className={box}
        href="https://www.linkedin.com/in/raholsn"
        target="_blank"
      >
        <FontAwesomeIcon className={icon} icon={faLinkedin} />
      </a>
      <a
        className={box}
        href="https://github.com/raholsn"
        target="_blank"
      >
        <FontAwesomeIcon className={icon} icon={faGithub} />
      </a>
    </div>
  );
};
