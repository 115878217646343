import React from 'react';
import { Feed } from './feed/feed';
import { container } from './sidebar.module.css';
import { Tags } from './tags/tags';
import { Welcome } from './welcome/welcome';

export const Sidebar = (props: any) => {
  const tags = Object.values(props).map((post: any) => post.tags);

  return (
    <div className={container}>
      <Welcome />
      <Tags {...tags} />
      {/* <Feed /> */}
    </div>
  );
};
