import React from 'react';
import { ArticleOverview } from './article-overview/article-overview';
import { container } from './main.module.css';

export const Main: React.FC<any> = (props) => {
  return (
    <div className={container}>
      <ArticleOverview {...props} />
    </div>
  );
};
