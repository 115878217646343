import { Link } from 'gatsby';
import React from 'react';
import { container } from './tag.module.css';

export const Tag: React.FC<{ name: string; amount: number }> = ({
  name,
  amount,
}) => {
  return (
    <Link className={container} to={`tags/${name}`}>
      {name}
      <span>({amount})</span>
    </Link>
  );
};
