import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import {
  container,
  featureImage,
  date,
  header,
  summary,
  continueReading,
} from './article-overview.module.css';
export const ArticleOverview: React.FC<any> = (postsData) => {
  const posts = Object.values(postsData).map((post: any, i) => {
    return (
      <div key={post.slug} className={container}>
        {post.featuredImage !== undefined ? (
          <Img
            className={featureImage}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'left',
            }}
            fluid={post.featuredImage}
          />
        ) : null}
        <div className={header}>
          <Link to={post.slug}>{post.title}</Link>
        </div>
        <div className={date}>
          <div style={{ marginRight: '5px' }}>
            <FontAwesomeIcon color="#929292" icon={faClock} />
          </div>
          <div>{post.date}</div>
        </div>
        <div className={summary}>
          <p>{post.html}</p>
        </div>
        <div>
          <Link className={continueReading} to={post.slug}>
            {'Continue reading    '}
            <FontAwesomeIcon
              className="article-overview-angle-right"
              icon={faAngleRight}
            />
          </Link>
        </div>
      </div>
    );
  });
  return <>{posts}</>;
};
